import { gql } from "@apollo/client";

export const GET_HEADER_OPTIONS = gql`
query headerOptions {
    topMenu : menu(id: "Top Header Menu", idType: NAME) {
      menuItems {
        edges {
          node {
            id
            url
            label
            path
          }
        }
      }
    }
  	mainMenu : menu(id: "Main Menu", idType: NAME) {
      menuItems(first: 1000) {
        nodes {
            id
            key: id
            parentId
            title: label
            url
            path
            menuItem{
              description
              largeDescription
            }
        }
      }
    }
  }
`;

export const SEARCH = gql`
query search($search: String!) {
    searchWP(first: 10, where: {input: $search}) {
      edges {
        node {
          id
          uri
          ... on Page {
            title
            pageOptions{
              excerpt
            }
          }
          ... on Post {
            title
            excerpt
            blogPost{
              excerpt
            }
          }
        }
      }
    }
  }
`;

export const GET_FOOTER_OPTIONS = gql`
query footerOptions {
    siteOptions {
      siteOptions {
        contactInformation
        facebookLink
        linkedinLink
        copyrightContent
        disclaimerText
        logos {
          link
          logo {
            sourceUrl
            srcSet
            sizes
            altText
            title
            mediaDetails{
              width
              height
            }
          }
        }
      }
    }
    menu(id: "Footer Menu", idType: NAME) {
      menuItems {
        edges {
          node {
            id
            url
            label
            path
          }
        }
      }
    }
  }
`;

export const GET_404_DATA = gql`
query errorOptions {
    siteOptions {
      siteOptions {
        errorContent
      }
    }
  }
`;

export const GET_MINIMAL_FOOTER_OPTIONS = gql`
query footerOptions {
    siteOptions {
      siteOptions {
        contactInformation
        facebookLink
        linkedinLink
        copyrightContent
        disclaimerText
        logos {
          link
          logo {
            sourceUrl
          }
        }
      }
    }
    menu(id: "Minimal Footer Menu", idType: NAME) {
      menuItems {
        edges {
          node {
            id
            url
            label
            path
          }
        }
      }
    }
  }
`;

export const GET_PAGE_OPTIONS = gql`
query pageOptions($slug: String) {
    pageBy(uri: $slug) {
      pageOptions {
        callToAction {
          ... on CTA {
            cta {
              button
              content
            }
          }
        }
        largeCta {
          ... on LargeCTA {
            largeCta{
              content
              backgroundImage{
                sourceUrl
              }
              type
            }
          }
        }
      }
    }
  }
`;

export const GET_HOME = gql `
query pageLookup($slug: String) {
    pageBy(uri: $slug) {
      id
      databaseId
      title
      date
      uri
      seo {
        canonical
        title
        metaDesc
        focuskw
        metaRobotsNoindex
        metaRobotsNofollow
        metaKeywords
        opengraphAuthor
        opengraphDescription
        opengraphTitle
        opengraphDescription
        opengraphImage {
            altText
            sourceUrl
            srcSet
        }
        opengraphUrl
        opengraphSiteName
        opengraphPublishedTime
        opengraphModifiedTime
        opengraphPublisher
        opengraphSiteName
        opengraphType
        twitterTitle
        twitterDescription
        twitterImage {
            altText
            sourceUrl
            srcSet
        }
        breadcrumbs {
            url
            text
        }
        cornerstone
        schema {
            pageType
            articleType
            raw
        }
        readingTime
      }
      home {
        heroImage {
          altText
          sourceUrl
        }
        heroButtons {
          button
        }
        heroTitle
        heroSubtitle
        widgets {
          content
          image {
            altText
            srcSet
            title
            sourceUrl
            sizes
            mediaDetails {
              width
              height
              sizes(include: _640_400_IMAGE_SIZE) {
                sourceUrl
              }
            }
          }
        }
        differenceImage {
          altText
          srcSet
          title
          sourceUrl
          sizes
          mediaDetails {
            width
            height
            sizes(include: _1140_960_IMAGE_SIZE){
              sourceUrl
            }
          }
        }
        differenceVideo
        differenceContent
        testimonials{
          testimonial
        }
        givingContent
        givingWidgetContent
        blogTitle
    }
    }
}`;

export const GET_HOME_BLOG_POSTS = gql`
    query blogPosts{
    posts(first: 3) {
    	pageInfo{
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
        total
      }
      edges{
        cursor
        node{
          id
          databaseId
          title
          uri
          date
          categories{
            edges{
              node{
              	uri
                name
              }
            }
          }
          featuredImage {
            node {
              id
              altText
              srcSet
              title
              sourceUrl
              sizes
              mediaDetails {
                width
                height
                sizes(include: _400_400_IMAGE_SIZE){
                  sourceUrl
                }
              }
            }
          }
          blogPost {
            excerpt
          }
        }
      }
    }
  }`;

export const GET_PAGE = gql `
query pageLookup($slug: String) {
    pageBy(uri: $slug) {
      id
      databaseId
      title
      date
      uri
      seo {
        canonical
        title
        metaDesc
        focuskw
        fullHead
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphAuthor
        opengraphDescription
        opengraphTitle
        opengraphDescription
        opengraphImage {
            altText
            sourceUrl
            srcSet
        }
        opengraphUrl
        opengraphSiteName
        opengraphPublishedTime
        opengraphModifiedTime
        twitterTitle
        twitterDescription
        twitterImage {
            altText
            sourceUrl
            srcSet
        }
        breadcrumbs {
            url
            text
        }
        cornerstone
        schema {
            pageType
            articleType
            raw
        }
        readingTime
        fullHead
      }
      pageOptionsNotHome{
        hideHeaderAndFooter
        minimalHeaderAndFooter
      }
      pageBuilder{
        contentRows {
            type
            content
            noPadding
            noMargin
            wysiwygTitle
            backgroundColor
            smallImage
            narrow
            video
            script
            form
            carousel{
                content
                image{
                  sourceUrl
                  altText
                  mediaDetails{
                    sizes(include: _740_640_IMAGE_SIZE) {
                        sourceUrl
                        height
                        width
                      }
                  }
                }
              }
            splitContent {
                content
            }
            threeColumnContent {
                content
            }
            image {
              sourceUrl
              altText
              mediaDetails {
                  sizes {
                      sourceUrl
                      height
                      width
                  }
              }
            }
            cardWidgets {
                title
                content
              }
            tableHeader {
              content
            }
            tableRows {
                rowContent {
                  content
                }
            }
              accordions {
                title
                details
              }
              testimonials {
                testimonial
              }
              team {
                ... on Staff {
                  title
                  staff{
                    image {
                      sourceUrl
                      altText
                      mediaDetails {
                        sizes(include: _480_480_IMAGE_SIZE) {
                          sourceUrl
                        }
                      }
                    }
                      biography
                      title
                  }
                }
              }
              widgetsPerRow
              imageWidgets {
                image {
                  altText
                  sourceUrl
                  mediaDetails {
                    sizes(include: _1040_400_IMAGE_SIZE) {
                      sourceUrl
                    }
                  }
                }
                content
              }
              iconWidgets {
                image {
                  sourceUrl
                  altText
                }
                content
              }
              callToActionContent
              callToActionLocation
              backgroundImage {
                sourceUrl
              }
              blog {
                ... on Post {
                  title
                  date
                  uri
                  excerpt
                  blogPost{
                    excerpt
                  }
                  categories{
                    edges{
                      node{
                        uri
                        name
                      }
                    }
                  }
                  featuredImage {
                    node {
                      id
                      sourceUrl
                      altText
                      mediaDetails {
                        sizes(include: _400_400_IMAGE_SIZE){
                          sourceUrl
                        }
                      }
                    }
                  }
                }
              }
              factsheets{
                content
                image{
                  sourceUrl
                  altText
                  mediaDetails{
                    sizes(include: _640_400_IMAGE_SIZE){
                      sourceUrl
                    }
                  }
                }
              }
        }
      }
    }
  }
`;

export const GET_BLOG_PAGE = gql `
query pageLookup($pageId: Int) {
    pageBy(pageId: $pageId) {
      databaseId
      title
      date
      uri
      seo {
        canonical
        title
        metaDesc
        focuskw
        fullHead
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphAuthor
        opengraphDescription
        opengraphTitle
        opengraphDescription
        opengraphImage {
            altText
            sourceUrl
            srcSet
        }
        opengraphUrl
        opengraphSiteName
        opengraphPublishedTime
        opengraphModifiedTime
        twitterTitle
        twitterDescription
        twitterImage {
            altText
            sourceUrl
            srcSet
        }
        breadcrumbs {
            url
            text
        }
        cornerstone
        schema {
            pageType
            articleType
            raw
        }
        readingTime
        fullHead
      }      
    }
  }
`;

export const GET_TOPICS = gql `
  query getTopics {
    topics(first: 1000, where: {hideEmpty: true}) {
      nodes {
        name
        uri
      }
    }
  }
`;

export const GET_CATEGORIES = gql `
  query getCategories {
    categories(first: 1000, where: {hideEmpty: true}) {
      nodes {
        name
        uri
      }
    }
  }
`;

export const GET_BLOG_FEATURED_IMAGE = gql`
  query getDefaultImage{
    siteOptions {
      siteOptions {
        backendUrl
        formSecretToken
        defaultImage{
          altText
          srcSet
          title
          sourceUrl
          sizes
          mediaDetails{
            width
            height
            sizes(include: _400_400_IMAGE_SIZE) {
              sourceUrl
            }
          }
        }
      }
    }
  }
`;

export const GET_BLOG_POSTS = gql`
    query blogPosts(
        $first: Int
        $last: Int
        $after: String
        $before: String
    ) {
    posts(first: $first, last: $last, after: $after, before: $before) {
    	pageInfo{
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
        total
      }
      edges{
        cursor
        node{
          id
          databaseId
          title
          uri
          date
          categories{
            edges{
              node{
              	uri
                name
              }
            }
          }
          featuredImage {
            node {
              id
              altText
              srcSet
              title
              sourceUrl
              sizes
              mediaDetails {
                width
                height
                sizes(include: _400_400_IMAGE_SIZE) {
                  sourceUrl
                }
              }
            }
          }
          blogPost {
            excerpt
          }
        }
      }
    }
  }`;

export const GET_BLOG_OPTIONS = gql`
query blogOptions {
    siteOptions {
      siteOptions {
        subtitle
        cta {
          ... on CTA {
            cta {
              button
              content
            }
          }
        }
        blogLargeCta {
          ... on LargeCTA {
            largeCta{
              backgroundImage{
                sourceUrl
              }
              content
              type
            }
          }
        }
      }
    }
  }
`;

  export const GET_CATEGORY_POSTS = gql`
    query categoryPosts(
        $first: Int
        $last: Int
        $after: String
        $before: String,
        $category: String,
    ) {
    categories(where: {slug: [$category]}) {
        edges {
            node {
                id
                name
                posts(first: $first, last: $last, after: $after, before: $before) {
                    pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                    }
                    edges {
                    cursor
                    node {
                        id
                        databaseId
                        title
                        uri
                        date
                        categories {
                          edges {
                            node {
                              id
                              uri
                              name
                            }
                          }
                        }
                        featuredImage {
                          node {
                            id
                            altText
                            srcSet
                            title
                            sourceUrl
                            sizes
                            mediaDetails {
                              width
                              height
                              sizes(include: _400_400_IMAGE_SIZE) {
                                sourceUrl
                              }
                            }
                          }
                        }
                        blogPost {
                          excerpt
                        }
                    }
                    }
                }
            }
        }
    }
  }`;

export const GET_TOPIC_POSTS = gql`
  query topicPosts(
      $first: Int
      $last: Int
      $after: String
      $before: String,
      $topic: String,
  ) {
  topics(where: {slug: [$topic]}) {
      edges {
          node {
              id
              name
              posts(first: $first, last: $last, after: $after, before: $before) {
                  pageInfo {
                  hasNextPage
                  hasPreviousPage
                  startCursor
                  endCursor
                  }
                  edges {
                  cursor
                  node {
                      id
                      databaseId
                      title
                      uri
                      date
                      categories {
                        edges {
                          node {
                            id
                            uri
                            name
                          }
                        }
                      }
                      featuredImage {
                        node {
                          id
                          altText
                          srcSet
                          title
                          sourceUrl
                          sizes
                          mediaDetails {
                            width
                            height
                            sizes(include: _400_400_IMAGE_SIZE) {
                              sourceUrl
                            }
                          }
                        }
                      }
                      blogPost {
                      excerpt
                      }
                  }
                  }
              }
          }
      }
  }
}`;

export const GET_POST = gql`
    query pageLookup($slug: String) {
    postBy(slug: $slug) {
      databaseId
      title
      date
      seo {
        canonical
        title
        metaDesc
        focuskw
        fullHead
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphAuthor
        opengraphDescription
        opengraphTitle
        opengraphDescription
        opengraphImage {
            altText
            sourceUrl
            srcSet
        }
        opengraphUrl
        opengraphSiteName
        opengraphPublishedTime
        opengraphModifiedTime
        twitterTitle
        twitterDescription
        twitterImage {
            altText
            sourceUrl
            srcSet
        }
        breadcrumbs {
            url
            text
        }
        cornerstone
        schema {
            pageType
            articleType
            raw
        }
        readingTime
        fullHead
      }
      categories {
        nodes {
          name
          uri
          slug
        }
      }
      featuredImage {
        node {
          id
          sourceUrl
          altText
          srcSet
          title
          sizes
          mediaDetails {
            height
            width
            sizes(include: _1660_800_IMAGE_SIZE) {
              sourceUrl
            }
          }
        }
      }
      blogPost {
        author {
          ... on Author {
            id
            title
            author {
              avatar {
                altText
                srcSet
                title
                sourceUrl
                sizes
                mediaDetails {
                  width
                  height
                  sizes(include: _480_480_IMAGE_SIZE) {
                    sourceUrl
                  }
                }
              }
              biography
            }
          }
        }
        featuredImageCaption
        hideFeaturedImageOnSingleView
        isEnhancedBlog
        overlapVideoInHeader
        simpleContent
        contentRows {
          type
          accordions {
            title
            details
          }
          backgroundImage {
            sourceUrl
          }
          button
          content
          splitContent {
            content
          }
          video
        }
      }
    }
  }`;

  export const GET_RELATED_POSTS = gql`
    query relatedPosts(
        $exclude: [ID],
        $category: String,
    ) {
    categories(where: {slug: [$category]}) {
        edges {
            node {
                id
                name
                posts(where: {notIn: $exclude}, first: 2) {
                    edges {
                    cursor
                    node {
                        id
                        databaseId
                        title
                        uri
                        date
                        categories {
                          edges {
                            node {
                              id
                              uri
                              name
                            }
                          }
                        }
                        featuredImage {
                            node {
                                id
                                altText
                                mediaDetails {
                                  width
                                  height
                                  sizes(include: _400_400_IMAGE_SIZE) {
                                      sourceUrl
                                  }
                                }
                            }
                        }
                        blogPost {
                          excerpt
                        }
                    }
                    }
                }
            }
        }
    }
  }`;

  export const GET_STAFF_DEPARTMENTS = gql `
  query getDepartments{
    departments(first: 100){
        nodes{
          name
          slug
          uri
          departments{
            introduction
          }
        }
    }
  }
`;

export const GET_STAFF_POSTS = gql`
  query staffPosts{
    allStaff(first: 1000) {
      edges{
        cursor
        node{
          id
          databaseId
          departments {
            nodes {
              name
              slug
            }
          }
          title
          staff {
            image {
              sourceUrl
              altText
              mediaDetails {
                sizes(include: _480_480_IMAGE_SIZE) {
                  sourceUrl
                }
              }
            }
            title
            biography
          }
        }
      }
    }
  }`;

  export const SEARCH_STAFF_POSTS = gql`
    query staffPosts(
      $search: String
    ){
      allStaff(where: {search: $search}) {
        edges {
          cursor
          node {
            id
            databaseId
            title
            departments{
              nodes{
                name
                slug
              }
            }
            staff {
              image {
                sourceUrl
                altText
                mediaDetails {
                  sizes(include: _480_480_IMAGE_SIZE) {
                    sourceUrl
                  }
                }
              }
              title
              biography
            }
          }
        }
      }
    }
  `;

export const GET_DEPARTMENT_STAFF = gql`
  query departmentStaff(
      $department: String
  ) {
  departments(where: {slug: [$department]}) {
    edges {
      node {
        id
        name
        staff {
          edges {
            cursor
            node {
              id
              databaseId
              title
              staff {
                image {
                  sourceUrl
                  altText
                  mediaDetails {
                    sizes(include: _480_480_IMAGE_SIZE) {
                      sourceUrl
                    }
                  }
                }
                title
                biography
              }
            }
          }
        }
      }
    }
  }
}`;

export const GET_STAFF_OPTIONS = gql`
query staffOptions {
    siteOptions {
      siteOptions {
        staffHeaderTitle
        staffHeaderSubtitle
        staffIntroduction
        staffCalloutContent
        staffCalloutImage{
          altText
          sourceUrl
          srcSet
          sizes
          title
          altText
          mediaDetails{
            width
            height
          }
        }
        staffCta {
          ... on CTA {
            cta {
              button
              content
            }
          }
        }
        staffLargeCta {
          ... on LargeCTA {
            largeCta{
              backgroundImage{
                sourceUrl
              }
              content
              type
            }
          }
        }
      }
    }
  }
`;

export const SITE_SEARCH = gql`
  query siteSearch(
      $search: String!
  ){
      searchWP(where: {input: $search, nopaging: true}, first: 500) {
        nodes {
          ... on Post {
            id
            title
            uri
            blogPost{
              excerpt
            }
          }
          ... on Page {
            id
            title
            uri
            pageOptions {
              excerpt
            }
          }
          contentTypeName
        }
      }
    }`;

    export const GET_CALCULATOR_OPTIONS = gql`
    query calculatorOptions {
        siteOptions {
          generosityCalculatorOptions {
            cCorpFederalTaxRate
            cCorpCapitalGainsTaxRate
            cCorpCapitalGainsTaxRateViaCharitableTrust
            sCorpFederalTaxRate
            sCorpCapitalGainsTaxRate
            sCorpCapitalGainsTaxRateViaCharitableTrust
            partnershipFederalTaxRate
            partnershipCapitalGainsTaxRate
            partnershipCapitalGainsTaxRateViaCharitableTrust
            realestateFederalTaxRate
            realestateCapitalGainsTaxRate
            realestateCapitalGainsTaxRateViaCharitableTrust
            disclaimer
          }
        }
      }
    `;
  
  export const GET_GENEROSITY_CALCULATOR_PAGE = gql `
    query pageLookup {
        pageBy(uri: "generosity-calculator") {
          databaseId
          title
          date
          uri
          seo {
            canonical
            title
            metaDesc
            focuskw
            fullHead
            metaRobotsNoindex
            metaRobotsNofollow
            opengraphAuthor
            opengraphDescription
            opengraphTitle
            opengraphDescription
            opengraphImage {
                altText
                sourceUrl
                srcSet
            }
            opengraphUrl
            opengraphSiteName
            opengraphPublishedTime
            opengraphModifiedTime
            twitterTitle
            twitterDescription
            twitterImage {
                altText
                sourceUrl
                srcSet
            }
            breadcrumbs {
                url
                text
            }
            cornerstone
            schema {
                pageType
                articleType
                raw
            }
            readingTime
            fullHead
          }
          pageOptionsNotHome{
            hideHeaderAndFooter
            minimalHeaderAndFooter
          }
          generosityCalculator{
            title
            introduction
            featuredImage{
              sourceUrl
              srcSet
              sizes
              title
              altText
              mediaDetails{
                width
                height
              }
            }
            video
            rightContent
            callToActionContent
          }
        }
      }
    `;

    export const GET_IMPACT_PAGE = gql `
    query pageLookup {
      pageBy(uri: "impact") {
        databaseId
        title
        date
        uri
        seo {
          canonical
          title
          metaDesc
          focuskw
          fullHead
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphAuthor
          opengraphDescription
          opengraphTitle
          opengraphDescription
          opengraphImage {
              altText
              sourceUrl
              srcSet
          }
          opengraphUrl
          opengraphSiteName
          opengraphPublishedTime
          opengraphModifiedTime
          twitterTitle
          twitterDescription
          twitterImage {
              altText
              sourceUrl
              srcSet
          }
          breadcrumbs {
              url
              text
          }
          cornerstone
          schema {
              pageType
              articleType
              raw
          }
          readingTime
          fullHead
        }
        pageOptionsNotHome{
          hideHeaderAndFooter
          minimalHeaderAndFooter
        }
        impact{
          heroImage{
            sourceUrl
          }
          heroLogo{
            sourceUrl
          }
          heroContent
          grantsTitle
          grantsChartImage{
            sourceUrl
            srcSet
            sizes
            title
            mediaDetails{
              width
              height
            }
          }
          grantsTotal
          contributionsTitle
          contributionsChartImage{
            sourceUrl
            srcSet
            sizes
            title
            mediaDetails{
              width
              height
            }
          }
          contributionsTotal
          quarterlyFundsImage{
            sourceUrl
            srcSet
            sizes
            title
            mediaDetails{
              width
              height
            }
          }
          quarterlyFundsTitle
          quarterlyFundsOpenedYear
          quarterlyFundsOpenedSinceFounding
          nonprofitsImage {
            sourceUrl
            srcSet
            sizes
            title
            altText
            mediaDetails{
              width
              height
            }
          }
          nonprofitsTitle
          nonprofitsSupportedYear
          nonprofitsSupportedSinceFounding
          impactImage {
            sourceUrl
            srcSet
            sizes
            title
            altText
            mediaDetails{
              width
              height
            }
          }
          impactRows{
            title
            content
            icon{
              sourceUrl
              altText
            }
            image{
              sourceUrl
              srcSet
              sizes
              title
              altText
              mediaDetails{
                width
                height
              }
            }
            imageTitle
          }
          legacyImage{
            sourceUrl
            srcSet
            sizes
            title
            altText
            mediaDetails{
              width
              height
            }
          }
          legacyTitle
          legacySubtitle
          legacyContent
          legacyLink
          recentDownload{
            mediaItemUrl
          }
          recentDownloadTitle
          pastDownloads{
            name
            file{
              mediaItemUrl
            }
          }
          aboutContent
        }
      }
    }
    `;

export const GET_REDIRECTS = gql `
  query getRedirects {
    redirection{
      redirects{
        origin
        target
        type
        code
      }
    }
  }
`;