import { ApolloClient, InMemoryCache } from "@apollo/client";
import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev"

if (process.env.NODE_ENV === "development") {  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

export const client = new ApolloClient({
  uri: `${process.env.NEXT_PUBLIC_WORDPRESS_API_URL}/graphql`,
  cache: new InMemoryCache(),
  headers: {
    'gcdn-force': 1
  },
  defaultOptions: {
    query: {
        fetchPolicy: 'network-only', // Used for first execution
        nextFetchPolicy: 'cache-first', // Used for subsequent executions
    }
  }
});